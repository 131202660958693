<template>
  <!-- <span id="reviews"></span>
    <CollectionWidget :glass="glass" />
    <br /><br /><br />
    <RelatedSlider />
     -->
    <div class="contenudesk"><br /><br /><br /><br /></div>
    <Footer :language="language" :displayModalMenu="displayModalMenu" />

    <PopupShare :glass="glass" :photo="photoDatas.scene1" :urlPhotoSlider="urlPhotoSlider" :favorite="favoriteShare" v-if="displayShare" />
</template>

<script>
import RelatedSlider from '../../../components/widget/RelatedSlider.vue'
import CollectionWidget from '../../../components/widget/CollectionWidget.vue'
import Footer from '../../../components/layout/Footer.vue'
import PopupShare from '../../../components/elements/PopupShare.vue'

export default {
    name: 'CollectionEnd',
    props: ["language","glass", "photos", "favoriteShare", "displayShare","urlPhotoSlider","displayModalMenu","photoDatas"],
    components: {
        Footer,
        CollectionWidget,
        RelatedSlider,
        PopupShare,
    },
    mounted(){
        
    }
}
</script>

<style>

</style>