<template>
  <div class="content-reviews">
    <div class="left">
      <span class="rate">{{average.toFixed(1)}}</span>
      <div class="note">

        <img v-if="average>=0.5" src="../../../../public/assets/images/star2.svg" width="29" />
        <img v-else src="../../../../public/assets/images/star_empty2.svg" style="opacity: 0.2;" width="21" />

        <img v-if="average>=1.5" src="../../../../public/assets/images/star2.svg" width="29" />
        <img v-else src="../../../../public/assets/images/star_empty2.svg" style="opacity: 0.2;" width="29" />

        <img v-if="average>=2.5" src="../../../../public/assets/images/star2.svg" width="29" />
        <img v-else src="../../../../public/assets/images/star_empty2.svg" style="opacity: 0.2;" width="29" />

        <img v-if="average>=3.5" src="../../../../public/assets/images/star2.svg" width="29" />
        <img v-else src="../../../../public/assets/images/star_empty2.svg" style="opacity: 0.2;" width="29" />

        <img v-if="average>=4.5" src="../../../../public/assets/images/star.svg" width="29" />
        <img v-else src="../../../../public/assets/images/star_empty2.svg" style="opacity: 0.2;" width="29" />

        
      </div>
      <div class="count"  v-if="reviews">
        <span v-if="language=='fr'">Basé sur {{reviews.length}} avis</span>
        <span v-else>Based on {{reviews.length}} reviews</span>
      </div>
      <div class="count"  v-else>Basé sur 0 avis</div>
      <br />
      <a  v-if="language=='fr'" class="write" @click="toReview()">Rédiger un avis</a>
      <a  v-else class="write" @click="toReview()">Write a review</a>
    </div>
    <div class="right">
      <div class="sort">
       <span  v-if="language=='fr'"  @click="showFilters">Trier par</span>
       <span  v-else  @click="showFilters">Sort by</span>

        <div class="sorting-list" v-if="displayFilters">
          <div v-if="language=='fr'" @click="sortReviews('rating_asc')" class="sorting-item" :class="{active:currentFilter=='rating_asc'}">Note (+ petit)</div>
          <div v-else @click="sortReviews('rating_asc')" class="sorting-item" :class="{active:currentFilter=='rating_asc'}">Rate (+ less)</div>
          <div v-if="language=='fr'" @click="sortReviews('rating_desc')" class="sorting-item" :class="{active:currentFilter=='rating_desc'}">Note (+ grand)</div>
          <div v-else @click="sortReviews('rating_desc')" class="sorting-item" :class="{active:currentFilter=='rating_desc'}">Rate (+ more)</div>
          <div v-if="language=='fr'" @click="sortReviews('date_asc')" class="sorting-item" :class="{active:currentFilter=='date_asc'}">Date (plus récent)</div>
          <div v-else @click="sortReviews('date_asc')" class="sorting-item" :class="{active:currentFilter=='date_asc'}">Date (more rescent)</div>
          <div v-if="language=='fr'" @click="sortReviews('date_desc')" class="sorting-item" :class="{active:currentFilter=='date_desc'}">Date (moins récent)</div>
          <div v-else @click="sortReviews('date_desc')" class="sorting-item" :class="{active:currentFilter=='date_desc'}">Date (less recent)</div>
          <div v-if="language=='fr'" @click="sortReviews('picture')" class="sorting-item" :class="{active:currentFilter=='picture'}">Avec photo</div>
          <div v-else @click="sortReviews('picture')" class="sorting-item" :class="{active:currentFilter=='picture'}">With picture</div>
        </div>
      </div>

      <div class="reviews">
        <template v-if="reviews!=null && reviews.length>0">

          
          <div class="my-slider-reviews">
            <div class="content-rating" v-for="(reviewGroup, index) in reviewGroups" :key="index" >
                
              <div v-for="(review, index2) in reviewGroup" :key="review.id" >
              
                <div class="comment-name">Nom Prénom</div>
                <div class="comment-title">
                  {{review.title}}
                </div>
                <div class="rating">
                  <img v-if="review.rate>=1" src="../../../../public/assets/images/star2.svg" width="19" />
                  <img v-else src="../../../../public/assets/images/star_empty2.svg" style="opacity: 0.2;" width="19" /> 
                  
                  <img v-if="review.rate>=2" src="../../../../public/assets/images/star2.svg" width="19" />
                  <img v-else src="../../../../public/assets/images/star_empty2.svg" style="opacity: 0.2;" width="19" /> 

                  <img v-if="review.rate>=3" src="../../../../public/assets/images/star2.svg" width="19" />
                  <img v-else src="../../../../public/assets/images/star_empty2.svg" style="opacity: 0.2;" width="19" /> 

                  <img v-if="review.rate>=4" src="../../../../public/assets/images/star2.svg" width="19" />
                  <img v-else src="../../../../public/assets/images/star_empty2.svg" style="opacity: 0.2;" width="19" /> 

                  <img v-if="review.rate>=5" src="../../../../public/assets/images/star2.svg" width="19" />
                  <img v-else src="../../../../public/assets/images/star_empty2.svg" style="opacity: 0.2;" width="19" />  

                </div>
                <div class="comment">
                  {{review.comment}}
                </div>
                <img :src="review.photo" v-if="review.photo!=null" width="150" />
              </div>
            </div> 
            
          </div>
        </template>
        <div  v-else><br /><br />
          <span v-if="language=='fr'" >Aucun avis client pour le moment.</span>
          <span v-else >No review for the moment.</span>
        </div>
      </div>

    </div>
   
  </div>
</template>

<script>

import {APIRequest} from "@simb2s/senseye-sdk";
import emitter from 'tiny-emitter/instance';
import { tns } from "../../../../node_modules/tiny-slider/src/tiny-slider"

export default {
  name: 'ReviewsToggle',
  props: ['glass','language'],
  data() {
    return {
      reviews: null,
      average:0,
      displayFilters:false,
      slider:null,
      initial_reviews:null,
      currentFilter:'date_asc',
      tinySliderOptions: {
          container: '.my-slider-reviews',
          mouseDrag: true,
          navPosition: 'bottom',
          loop: false,
          items: 1,
    
          gutter: 0,
          swipeAngle: 45,
          nav: true,
          controls: true,
     
          startIndex: 0,
      },
    }
  },
  computed: {
    reviewGroups() {
      let curr = -1
      const groups = []
      const reviews = this.reviews
      for (let i = 0; i < reviews.length; i++) {
        if (i % 3 === 0) {
          curr++
        }
        groups[curr] = groups[curr] || []
        groups[curr].push(reviews[i])
      }
      return groups
    }
  },
  updated() {
    if(this.slider!=null)
    {
      this.slider.destroy()
    }
    this.slider = tns(
      this.tinySliderOptions
    );
  },
  async mounted(){
    
      let reviews=await APIRequest.list('reviews',null,null, 100,1,this.$route.params.id)
      this.reviews=reviews[0]
      this.initial_reviews=this.reviews
      let total_rating=0
      for(let i=0;i<this.reviews.length;i++)
      {
        total_rating+=this.reviews[i].rate
      }
      if(this.reviews.length>0)
      {
        this.average=total_rating/this.reviews.length

        this.slider = tns(
          this.tinySliderOptions
        );
      }
    
  },
  methods:{
    showFilters() {
      this.displayFilters = !this.displayFilters
    },
    sortReviews(filter) {
      this.displayFilters=false
      this.currentFilter=filter
      if(filter=="date_asc")
      {
        let reviews=this.initial_reviews
        reviews.sort((a,b) => (a.created_at > b.created_at) ? 1 : ((b.created_at > a.created_at) ? -1 : 0))
        this.reviews=reviews
      }
      if(filter=="date_desc")
      {
         let reviews=this.initial_reviews
        reviews.sort((a,b) => (a.created_at < b.created_at) ? 1 : ((b.created_at < a.created_at) ? -1 : 0))
        this.reviews=reviews
      }
      if(filter=="rating_asc")
      {
        let reviews=this.initial_reviews
        reviews.sort((a,b) => (a.rate > b.rate) ? 1 : ((b.rate > a.rate) ? -1 : 0))
        this.reviews=reviews
      }
      if(filter=="rating_desc")
      {
        let reviews=this.initial_reviews
        reviews.sort((a,b) => (a.rate < b.rate) ? 1 : ((b.rate < a.rate) ? -1 : 0))
        this.reviews=reviews
      }
      if(filter=="picture")
      {
        let reviews=this.initial_reviews
        let new_reviews=[]
        for(let i=0;i<reviews.length;i++)
        {
          if(reviews[i].photo!=null && reviews[i].photo!='')
          {
            new_reviews.push(reviews[i])
          }
          else
          {
            
          }
        }
        this.reviews=new_reviews
      }
    },
    async toReview(){
      if(localStorage.id_user_senseye_auth)
      {
        this.$router.push({ name: 'review', params: { id: 22 }})
      }
      else
      {
        emitter.emit('display-popup-login',true)
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@media screen and (max-width:760px)
{
  
  .content-reviews {
    text-align: center;
    position:relative;
  }
  .comment {
    font-size:15px !important;
  }

  .sort {
    float:none !important;
    left:5%;
    top:160px;
    position:absolute !important;
  }

  a.write {
    margin-top:0 !important;
    display:block !important;
    float:right;
    clear: both;
  
  }
  .content-rating {
    margin-top:50px;
  }
  .content-rating {
    text-align: left;
   
  }
  .count {
    position: relative;
    top:-40px;
    left:-10%;
    min-width: 125px;
    font-size: 17px !important;
    display:block !important;
    float:right;
    clear: both;
  }
  .note {
    position:relative;
    top:20px;
    left:-10%;
    display:inline-block !important;
    float:right;
    clear: both;
  }
  .rate
  {
    
    display:inline-block !important;
    font-size:90px !important;
  }
}
.comment-name{
  font-size: 18px;
  color: rgba(0, 0, 0, 0.45);
}
.content-reviews .left {
  width:30%;
}
.content-reviews .right {
  width:70%;
}
.content-reviews {
  background: rgba(192, 192, 192, 0.2) 0% 0% no-repeat padding-box;
  display:flex;
  width:100%;
  padding:10% 5%;
}
a.write:hover {
  background: transparent;
  color:#000;
}
a.write
{
  cursor: pointer;
  transition: all 0.3s;
  padding:10px 40px;
  font-size:15px;
  white-space: nowrap;
  font-weight: 600;
  color:#fff;
  text-decoration: none;
  border:1px solid black;
  margin-top:20px;
  background:#000;
}
.head-reviews
{
  margin-bottom:5px;
}
.count 
{
  font-size:20px;
  color:rgba(121, 121, 121, 1);
  display: block;
  font-size:21px;
  margin-top:5px;
}
.sort::after
{
  position:absolute;
  content:' ';
  width:30px;
  height:30px;
  transform: rotate(90deg);
  background-size:100% 100%;
  right:0px;
  top:-2px;
  background-image:url('../../../../public/assets/images/arrow_scan.svg');
}
.sorting-item:hover,
.sorting-item.active
{
  font-weight: 600;
}
.sorting-list
{
  background: rgb(243, 243, 243) 0% 0% no-repeat padding-box;
  position:absolute;
  left:0;
  min-width:110px;
  font-size:11px;
}
.sort 
{
  display:inline-block;
  float:right;
  padding-right:30px;
  cursor: pointer;
  position: relative;
  font-weight: 600;
  font-size:18px;
}
a.readmore
{
  font-size: 13px;
  text-decoration: none;
  color:#000;
  border-bottom: 1px solid black;
}
.rating 
{
  display:inline-block;
  margin-left:15px;
}
.comment 
{
  font-size:18px;
  margin-top:10px;
  margin-bottom:20px;
}
.comment-title
{
  display:inline-block;
  font-weight: 600;
  font-size: 22px;
}
.rate
{
  font-size: 130px;
  font-weight: 600;line-height: 0.7;
  margin-bottom:20px;
  display:block;
  
}
.rating img,
.note img
{
  display: inline-block;
  vertical-align: top;
}
.title
{
   display: inline-block;
   font-weight:600;
   font-size:20px;
  vertical-align: top;
  margin-left:15px;
  margin-top:15px;
}
.note
{
  
  clear: both;
  display: block;
  
  
}
body .reviews .tns-nav
{
  top:20px !important;
}
</style>
