<template>
    <div>
        <div class="collection-widget">
            <div class="head">
                <div class="toggle-choice" @click="selectToggle('reviews')">AVIS CLIENTS</div>
                <div class="toggle-choice" @click="selectToggle('delivery')">LIVRAISON & RETOURS</div>
                <div class="toggle-choice" @click="selectToggle('faq')">FAQ</div>

                <div class="bar" :style="'width:'+barWidth+'px;left:'+barPosition+'px;'"></div>
            </div>
            <div class="content">
                <ReviewsToggle :glass="glass" v-if="toggleSelected=='reviews'" />
                <FAQToggle :glass="glass" v-if="toggleSelected=='faq'" />
                <DeliveryToggle :glass="glass" v-if="toggleSelected=='delivery'" />
            </div>
        </div>
        <div class="presse-encart">
            <div class="title">ILS PARLENT DE <span class="outline">NOUS</span></div>

            <div class="contenumob slider">
                <div>
                    
                    <div class="carousel-cell">
                        <div class="presse-item">
                            <div class="logo"><img src="../../../public/assets/images/vogue.svg" /></div>
                            <div class="text-presse">
                                Through local and sustainable manufacturing in Paris, contemporary functional pieces with high-end materials and modern craftsmanship…
                            </div>
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="presse-item">
                            <div class="logo"><img src="../../../public/assets/images/vogue.svg" /></div>
                            <div class="text-presse">
                                Through local and sustainable manufacturing in Paris, contemporary functional pieces with high-end materials and modern craftsmanship…
                            </div>
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="presse-item">
                            <div class="logo"><img src="../../../public/assets/images/vogue.svg" /></div>
                            <div class="text-presse">
                                Through local and sustainable manufacturing in Paris, contemporary functional pieces with high-end materials and modern craftsmanship…
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="presse-content contenudesk">
                <div class="row">
                    <div class="presse-item">
                        <div class="logo"><img src="../../../public/assets/images/vogue.svg" /></div>
                        <div class="text-presse">
                            Through local and sustainable manufacturing in Paris, contemporary functional pieces with high-end materials and modern craftsmanship…
                        </div>
                    </div>
                     <div class="presse-item">
                        <div class="logo"><img src="../../../public/assets/images/hb.svg" /></div>
                        <div class="text-presse">
                            Through local and sustainable manufacturing in Paris, contemporary functional pieces with high-end materials and modern craftsmanship…
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="presse-item">
                        <div class="logo"><img src="../../../public/assets/images/figaro.svg" /></div>
                        <div class="text-presse">
                            Through local and sustainable manufacturing in Paris, contemporary functional pieces with high-end materials and modern craftsmanship…
                        </div>
                    </div>
                     <div class="presse-item">
                        <div class="logo"><img src="../../../public/assets/images/frenchtech.svg" /></div>
                        <div class="text-presse">
                            Through local and sustainable manufacturing in Paris, contemporary functional pieces with high-end materials and modern craftsmanship…
                        </div>
                    </div>
                </div>
            </div>
            <br /><br />
            <center><a class="see_all">VOIR TOUS LES ARTICLES</a></center>
        </div>
    </div>
</template>

<script>

import DeliveryToggle from "./DeliveryToggle.vue";
import FAQToggle from "./FAQToggle.vue";
import ReviewsToggle from "./ReviewsToggle.vue";
import {APIRequest} from "@simb2s/senseye-sdk";
import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider";

export default {
  name: 'CollectionWidget',
  props: ['glass'],
  data() {
    return {
        creationSliderOptions: {
            container: '.slider-creations',
            mouseDrag: true,
            navPosition:'bottom',
        
            autoplayHoverPause:true,
            autoplayButton:false,
            loop: true,
            items: 2,
            center:true,
            gutter: 10,
            swipeAngle: 45,
            nav:false,
            controls:true,
            prevButton:'#prev-creation',  // Node reference
            nextButton: '#next-creation',
            startIndex:0,
        },
        slider_creation:null,
      barWidth: 115,
      barPosition:0,
      toggleSelected:'reviews'
    }
  },
  components:{
    DeliveryToggle,
    FAQToggle,
    ReviewsToggle
  },
  async mounted(){
     
    if(window.innerWidth<1000)
      {
          this.barWidth=85
            this.slider_creation = tns(
                this.creationSliderOptions
            );
      }
      else if(window.innerWidth<1190)
      {
          this.barWidth=95
      }
  },
  methods:{
    async selectToggle(toggle){
        this.toggleSelected=toggle
      if(toggle=="reviews")
      {
         if(window.innerWidth<1000)
        {
            this.barWidth=85
        }
       else if(window.innerWidth<1190)
        {
            this.barWidth=95
        }
        
        else
        {
            this.barWidth=115
        }
          
        this.barPosition=0
      }
      else if(toggle=="delivery")
      {
           if(window.innerWidth<1000)
            {
                this.barWidth=140
                this.barPosition=100
            }

          else if(window.innerWidth<1190)
            {
                this.barWidth=160
                this.barPosition=115
            }
          
            else
            {
                this.barWidth=192
                this.barPosition=143
            }
      }
      else if(toggle=="faq")
      {
          

          if(window.innerWidth<1000)
            {
               this.barWidth=30
             this.barPosition=255
            }
            else if(window.innerWidth<1190)
            {
                 this.barWidth=32
                this.barPosition=290
            }
            else
            {
               this.barWidth=37
             this.barPosition=365
            }
      }
    },
  }
}
</script>

<style>

#next-creation
{
    position:absolute;
    top:50%;
    right:10px;
    transform: translateY(-50%);
    z-index:3;
}
#prev-creation img
{
    transform:rotate(-180deg) ;
}
#prev-creation
{
    position:absolute;
    top:50%;
    left:10px;
    z-index:3;
    transform: translateY(-50%);
}
.slider button 
{
    display:none !important;
}
.slider,
.slider-creations
{
    position: relative;

}
.presse-encart .see_all
{
    margin-left:auto;
    display: inline-block;
    color:#000;
    text-decoration: none;
    margin-right:auto;
    border-bottom:1px solid black;
}
.presse-encart .title .outline
{
    background-image: -webkit-linear-gradient(#000 50%, #000 50%);
    background-position: 0 0;
    background-size: 0% 0%;
    text-stroke: 1px #000;
    -webkit-text-stroke: 1px #000;
    color: #000;
    white-space: nowrap;
    color: transparent;
    text-decoration: none;
}
.presse-content
{
    position: relative;
    width:100%;
}
.presse-content .row
{
    position: relative;
    width:100%;
    margin-top:30px;
}
.presse-item:nth-child(2)
{
    margin-left:4%;
}
.presse-item .logo img
{
    display: inline-block;
}
.presse-item .logo 
{
     text-align:center;
}
.presse-item .text-presse::before
{
    width:7px;
    height:17px;
    content:' ';
    position: absolute;
    left: -15px;
    top:0;
    background-size:100% 100%;
    background-image:url('../../../public/assets/images/guillemet.svg')
}
.presse-item .text-presse
{
    font-size:13px;
    margin-top:20px;
    position:relative;
}
.presse-item
{
    padding:30px;
    background: rgba(253, 253, 253, 1) 0% 0% no-repeat padding-box;
box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
border-radius: 13px;
    display: inline-block;
    width:48%;
  
    vertical-align: top;
}
.presse-encart .title
{
    font-size:50px;
    text-align: center;
}
.presse-encart
{
    display: inline-block;
    vertical-align: top;
    width:40%;
    margin-left:5%;
}
.collection-widget .head .bar
{
    position:absolute;
    bottom:0;
    transition:all 0.3s ease;
    height:2px;
    background:#000;
}
.collection-widget .head .toggle-choice
{
    display: inline-block;
    vertical-align: top;
    cursor:pointer;
    margin-right:30px;
}
.collection-widget .head
{
    padding-bottom:15px;
    font-size:18px;
    position:relative;
    border-bottom:1px solid #dbdbdb;
    
}
.collection-widget 
{
    background: rgba(253, 253, 253, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
    border-radius: 13px;
    padding-left:50px;
    padding-right:50px;
    padding-top:30px;
    padding-bottom:30px;
    display: inline-block;
    vertical-align: top;
    width:45%;
    margin-left:5%;
}
.collection-widget .content
{
    padding-top:30px;
}
/* @media screen and (max-width:1190px)
{
.collection-widget .head
{
    padding-bottom:15px;
    font-size:15px;
    position:relative;
    border-bottom:1px solid #dbdbdb;
    
}
.collection-widget .head .toggle-choice
    {
        margin-right: 20px;
    }
}
@media screen and (min-width:760px) and (max-width:1000px)
{

    body .presse-encart
    {
        margin-top:50px;
        width:90%;
        margin-left:5%;
    }
    body .collection-widget 
    {
        width:90%;
        padding-left:20px;
        padding-right:20px;
    }

}
@media screen and (max-width:760px)
{
    .presse-item .logo img
    {
        width:100px;
        height:auto;
        margin-left:auto;
        margin-right:auto;
    }
    .collection-widget .head .toggle-choice
    {
        margin-right: 20px;
    }
    body .collection-widget .head

    {
        font-size:13px;
    }
    body .presse-item
    {
        width:100%!important;
    }
    .collection-widget 
    {
        width:90%;
        padding-left:20px;
        padding-right:20px;
    }
    .slider .tns-outer
    {
        padding-bottom:20px !important;
    }
    .presse-encart .title
    {
        font-size:32px;
    }
    body .presse-item
    {
        padding:20px;

    }
    body .carousel-cell
    {
        padding-top:10px !important;
        padding-bottom:10px !important;
    }
    body .presse-encart
    {
        margin-top:50px;
        width:100%;
        margin-left:0;
    }
} */
</style>