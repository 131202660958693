export default {
    obj: [
        {
            link: 'color',
            txt: 'COULEUR',
            txt_en: 'COLOR',
        },
        {
            link: 'texture',
            txt: 'TEXTURE',
            txt_en: 'TEXTURE',
        },
        {
            link: 'branch',
            txt: 'BRANCHES',
            txt_en: 'TEMPLE',
        },
        {
            link: 'tint',
            txt: 'VERRES',
            txt_en: 'LENS',
        },
        {
            link: 'gravure',
            txt: 'GRAVURE',
            txt_en: 'ENGRAVING',
            price: '(+10€)',
        },
    ]
}