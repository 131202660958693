<template>
    <div class="section faq">
   
        <h2 class="title"><img src="../../../../public/assets/images/arrow_black.png" width="75" /> FAQ</h2>
        <div class="refund-faq">
           
            <div class="faq-category" v-if="language=='fr'">

                <div class="question"><img src="../../../../public/assets/images/arrow_white.png" width="55" /> Comment VISAGES assure-t-elle la précision des lunettes sur-mesure à distance ?</div>
                <div class="answer">
                    VISAGES utilise un algorithme de scan 3D enrichi par Intelligence artificielle  pour garantir un ajustement parfait de chaque paire de lunettes. Après commande sur www.visages.com et le téléchargement de notre application mobile, un scan 3D de votre visage capture vos caractéristiques morphologiques avec une précision au dixième de millimètre. Cette technologie permet de créer des lunettes parfaitement ajustées, offrant confort et esthétique sur mesure.
                </div>
                <div class="question"><img src="../../../../public/assets/images/arrow_white.png" width="55" />  Quel est le processus pour commander une paire de lunettes sur-mesure?</div>
                <div class="answer">
                    <ul>
                        <li >Sélection du modèle :&nbsp;Choisissez votre modèle préféré.</li>
                        <li >Personnalisation :&nbsp;Personnalisez votre paire (couleur, textures, branches, verres, gravures).</li>
                        <li >Sélection du mode sur-mesure :&nbsp;Sélectionnez le mode sur-mesure, puis ajoutez-le au panier et validez votre commande.</li>
                        <li >Scan du visage :&nbsp;Après l'achat, téléchargez notre application mobile pour effectuer le scan 3D.</li>
                        <li>Livraison :&nbsp;Recevez vos lunettes sur-mesure directement chez vous.</li>
                    </ul>
                </div>
                <div class="question"><img src="../../../../public/assets/images/arrow_white.png" width="55" /> Que faire si je ne suis pas satisfait(e) de mon sur-mesure?</div>
                <div class="answer">
                    Nous garantissons l'ajustement de vos lunettes sur-mesure. Si votre première commande ne vous satisfait pas à 100%, nous fabriquerons un nouvel article à nos frais. Vous pouvez soumettre votre demande via la page contact, jusqu'à 10 jours après votre achat. 
                </div>
                <div class="question"><img src="../../../../public/assets/images/arrow_white.png" width="55" /> Comment fonctionne l’impression 3D?</div>
                <div class="answer">
                    L'impression 3D est une méthode de fabrication additive qui, contrairement aux techniques traditionnelles soustractives, utilise précisément la matière nécessaire pour chaque produit fabriqué. Nous bénéficions d'une grande variété de technologies d'impression 3D (SLS, MJF, DLP, SLM...), et fabriquons ainsi à partir de matériaux biosourcés, de résines translucides, de nylons multicolores ou encore de titane. VISAGES vise à exploiter pleinement cette technologie pour permettre aux artistes et aux marques de publier librement leurs créations sur la plateforme visages.com, en expérimentant de nouveaux concepts. Produites en Europe, sans contraintes de stock, à l’unité, à la demande, et avec une garantie zéro déchet, ces lunettes représentent un mouvement révolutionnaire pour l’industrie lunetière que VISAGES souhaite étendre.
                </div>

                <div class="question"><img src="../../../../public/assets/images/arrow_white.png" width="55" /> Quel est le délai de livraison pour ma commande?</div>
                <div class="answer">
                    Le délai de livraison varie selon le produit et sera indiqué lors du paiement.
                </div>



            </div>

            <div class="faq-category" v-else>

                <div class="question"><img src="../../../../public/assets/images/arrow_white.png" width="55" /> How does VISAGES ensure the precision of custom glasses remotely?</div>
                <div class="answer">
                    VISAGES uses a 3D scanning algorithm enhanced by artificial intelligence to ensure a perfect fit for each pair of glasses. After ordering on www.visages.com and downloading our mobile app, a 3D scan of your face captures your unique features with 1/10th of a millimeter accuracy. This technology creates perfectly fitted glasses, offering both comfort and custom aesthetics.
                </div>
                <div class="question"><img src="../../../../public/assets/images/arrow_white.png" width="55" />  What is the process for ordering a pair of custom glasses?</div>
                <div class="answer">
                    <ul>
                        <li >Model selection: Choose your favorite model.</li>
                        <li >Customisation: Customize your pair (color, textures, temples, lenses, engravings).</li>
                        <li >Made-to-fit option: Select the made-to-fit option, then add it to your cart and complete your order.</li>
                        <li >Face scan: After purchase, download our mobile app to perform the 3D scan.</li>
                        <li>Delivery: Receive your made-to-fit glasses directly at home.</li>
                    </ul>
                </div>
                <div class="question"><img src="../../../../public/assets/images/arrow_white.png" width="55" /> What if I am not satisfied with my custom-made order?</div>
                <div class="answer">
                    We guarantee the fit of your custom-made glasses. If your first order does not satisfy you 100%, we will manufacture a new item at our expense. You can submit your request via the contact page, up to 10 days after your purchase.
                </div>
                <div class="question"><img src="../../../../public/assets/images/arrow_white.png" width="55" /> How does 3D printing work?</div>
                <div class="answer">
                    3D printing is an additive manufacturing method that, unlike traditional subtractive techniques, uses precisely the material needed for each product. We benefit from a wide variety of 3D printing technologies (SLS, MJF, DLP, SLM...), and thus manufacture using biosourced materials, translucent resins, multicolored nylons, and even titanium. VISAGES aims to fully harness this technology to allow artists and brands to freely publish their creations on the visages.com platform, experimenting with new concepts. Produced in Europe, without stock constraints, individually, on-demand, and with a zero-waste guarantee, these glasses represent a revolutionary movement for the eyewear industry that VISAGES seeks to expand.

                </div>

                <div class="question"><img src="../../../../public/assets/images/arrow_white.png" width="55" /> What is the delivery time for my order?</div>
                <div class="answer">
                    The delivery time varies depending on the product and will be indicated at checkout.
                </div>



            </div>
        
        </div>
  
</div>
</template>

<script>
import jQuery  from 'jquery';

export default {
    name: "FaqCompotent",
    props:['language'],
    mounted(){
        jQuery(document).ready(function(){
								
          
            
            jQuery('.faq-category .question').click(function(){
                
                if(jQuery(this).next('.answer').is(':visible'))
                {
                    jQuery(this).next('.answer').slideUp();
                    jQuery(this).removeClass('open');
                }
                else
                {
                    jQuery(this).addClass('open');
                    jQuery(this).next('.answer').slideDown();
                }
                
            });
            
        });
    }
}
</script>

<style scoped>
@media screen and (max-width: 760px){
    .section.faq {
        padding-left:5% !important;
        padding-right:5% !important;
    }
}
.question{
    display: flex;
    align-items: center;
    border-bottom:0.25px solid black;
}
.question:first-child{
    border-top:0.25px solid black;
}
.section.faq {
    padding-top:0px;
    padding-bottom:250px;
    padding-left:20%;
    padding-right:20%;
}
.question {
    text-transform: none;
    font-size:28px;
    line-height:30px;
}
h2.title img,

.question img {
    margin-right:15px;
    display: inline-block;
}
.faq-category .question.open::after {
   
   
   content: '-';
 

}
.faq-category .question::after {
   line-height: 2;
    font-size:17px;
    position: absolute;
    height: 40px;
    width: 40px;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    transition: all 0.5s;
    content: '+';
    background:transparent;

}
h2.title {
    font-size:90px;
    font-weight: 600;
}
h2.title img {
    position:relative;
    top:5px;
}
</style>