export default {
    data: [
        {
            link: "custom",
            text: "Sur-mesure",
            text_en: "Custom-fit"
        },
        {
            link: "size",
            text: "One size",
            text_en: "One size"
        }
    ]
}