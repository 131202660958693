<template>
  <div class="content-reviews">
    <div class="head-reviews">
      <div class="count"  v-if="reviews">{{reviews.length}} Avis</div>
      <div class="count"  v-else>0 Avis</div>
      <a class="write" @click="toReview()">Rédiger un avis</a>
      
    </div>
    <div class="content">
      <div class="note">
        <img v-if="average>=0.5" src="../../../public/assets/images/star.svg" width="21" />
        <img v-else src="../../../public/assets/images/star-empty.svg" width="21" />

        <img v-if="average>=1.5" src="../../../public/assets/images/star.svg" width="21" />
        <img v-else src="../../../public/assets/images/star-empty.svg" width="21" />

        <img v-if="average>=2.5" src="../../../public/assets/images/star.svg" width="21" />
        <img v-else src="../../../public/assets/images/star-empty.svg" width="21" />

        <img v-if="average>=3.5" src="../../../public/assets/images/star.svg" width="21" />
        <img v-else src="../../../public/assets/images/star-empty.svg" width="21" />

        <img v-if="average>=4.5" src="../../../public/assets/images/star.svg" width="21" />
        <img v-else src="../../../public/assets/images/star-empty.svg" width="21" />

        <span class="rate">{{average.toFixed(1)}}</span>
      </div>
      <div class="title">NOTES ET COMMENTAIRES</div>
      
      <div class="sort">
       <span @click="showFilters">TRIER PAR</span>

        <div class="sorting-list" v-if="displayFilters">
          <div @click="sortReviews('rating_asc')" class="sorting-item" :class="{active:currentFilter=='rating_asc'}">Note (+ petit)</div>
          <div @click="sortReviews('rating_desc')" class="sorting-item" :class="{active:currentFilter=='rating_desc'}">Note (+ grand)</div>
          <div @click="sortReviews('date_asc')" class="sorting-item" :class="{active:currentFilter=='date_asc'}">Date (plus récent)</div>
          <div @click="sortReviews('date_desc')" class="sorting-item" :class="{active:currentFilter=='date_desc'}">Date (moins récent)</div>
          <div @click="sortReviews('picture')" class="sorting-item" :class="{active:currentFilter=='picture'}">Avec photo</div>
        </div>
      </div>
      
      <br /><br />
      <div class="reviews">
        <div v-if="reviews!=null && reviews.length>0">
          <div class="content-rating" v-for="(review, index) in reviews" :key="review.id">
            <div  v-if="index<4">
              <div class="comment-title">
                {{review.title}}
              </div>
              <div class="rating">
                <img v-if="review.rate>=1" src="../../../public/assets/images/star.svg" width="13" />
                <img v-else src="../../../public/assets/images/star-empty.svg" width="13" /> 
                
                <img v-if="review.rate>=2" src="../../../public/assets/images/star.svg" width="13" />
                <img v-else src="../../../public/assets/images/star-empty.svg" width="13" /> 

                <img v-if="review.rate>=3" src="../../../public/assets/images/star.svg" width="13" />
                <img v-else src="../../../public/assets/images/star-empty.svg" width="13" /> 

                <img v-if="review.rate>=4" src="../../../public/assets/images/star.svg" width="13" />
                <img v-else src="../../../public/assets/images/star-empty.svg" width="13" /> 

                <img v-if="review.rate>=5" src="../../../public/assets/images/star.svg" width="13" />
                <img v-else src="../../../public/assets/images/star-empty.svg" width="13" />  

              </div>
              <div class="comment">
                {{review.comment}}
              </div>
              <img :src="review.photo" v-if="review.photo!=null" width="150" />
            </div>
          </div>
        </div>
        <div v-else>Aucun avis client pour le moment.</div>
      </div>
      <br /><br /><br />
      <center>
        <router-link class="readmore" :to="{ name: 'reviews', params: { id: 22 }}">LIRE PLUS DE COMMENTAIRES</router-link>
       
      </center>
    </div>
  </div>
</template>

<script>

import {APIRequest} from "@simb2s/senseye-sdk";
import emitter from 'tiny-emitter/instance';

export default {
  name: 'ReviewsToggle',
  props: ['glass'],
  data() {
    return {
      reviews: null,
      average:0,
      displayFilters:false,
      initial_reviews:null,
      currentFilter:'date_asc'
    }
  },
  async mounted(){
    
      let reviews=await APIRequest.list('reviews',null,null, 100,1,this.$route.params.id)
      this.reviews=reviews[0]
      this.initial_reviews=this.reviews
      let total_rating=0
      for(let i=0;i<this.reviews.length;i++)
      {
        total_rating+=this.reviews[i].rate
      }
      if(this.reviews.length>0)
      {
        this.average=total_rating/this.reviews.length
      }
      

  },
  methods:{
    showFilters() {
      this.displayFilters = !this.displayFilters
    },
    sortReviews(filter) {
      this.currentFilter=filter
      if(filter=="date_asc")
      {
        let reviews=this.initial_reviews
        reviews.sort((a,b) => (a.created_at > b.created_at) ? 1 : ((b.created_at > a.created_at) ? -1 : 0))
        this.reviews=reviews
      }
      if(filter=="date_desc")
      {
         let reviews=this.initial_reviews
        reviews.sort((a,b) => (a.created_at < b.created_at) ? 1 : ((b.created_at < a.created_at) ? -1 : 0))
        this.reviews=reviews
      }
      if(filter=="rating_asc")
      {
        let reviews=this.initial_reviews
        reviews.sort((a,b) => (a.rate > b.rate) ? 1 : ((b.rate > a.rate) ? -1 : 0))
        this.reviews=reviews
      }
      if(filter=="rating_desc")
      {
        let reviews=this.initial_reviews
        reviews.sort((a,b) => (a.rate < b.rate) ? 1 : ((b.rate < a.rate) ? -1 : 0))
        this.reviews=reviews
      }
      if(filter=="picture")
      {
        let reviews=this.initial_reviews
        let new_reviews=[]
        for(let i=0;i<reviews.length;i++)
        {
          if(reviews[i].photo!=null && reviews[i].photo!='')
          {
            new_reviews.push(reviews[i])
          }
          else
          {
            
          }
        }
        this.reviews=new_reviews
      }
    },
    async toReview(){
      if(localStorage.id_user_senseye_auth)
      {
        this.$router.push({ name: 'review', params: { id: 22 }})
      }
      else
      {
        emitter.emit('display-popup-login',true)
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a.write
{
  font-size:13px;
  font-weight: 600;
  color:#000;
  text-decoration: none;
  border-bottom:1px solid black;
  margin-left:20px;
}
.head-reviews
{
  margin-bottom:5px;
}
.count 
{
  display: inline-block;
  font-size:13px;
}
.sort::after
{
  position:absolute;
  content:' ';
  width:30px;
  height:30px;
  transform: rotate(90deg);
  background-size:100% 100%;
  right:0px;
  top:-7px;
  background-image:url('../../../public/assets/images/arrow_scan.svg');
}
.sorting-item:hover,
.sorting-item.active
{
  font-weight: 600;
}
.sorting-list
{
  background-color: rgba(192, 192, 192, 0.2) 0% 0% no-repeat padding-box;
  position:absolute;
  left:0;
  min-width:110px;
  font-size:11px;
}
.sort 
{
  display:inline-block;
  float:right;
  padding-right:30px;
  position: relative;
  font-size:13px;
}
a.readmore
{
  font-size: 13px;
  text-decoration: none;
  color:#000;
  border-bottom: 1px solid black;
}
.rating 
{
  margin-top:5px;
}
.comment 
{
  font-size:11px;
}
.comment-title
{
  font-weight: 600;
  font-size: 13px;
}
.rate
{
  font-size: 18px;
  margin-left: 20px;
  
}
.rating img,
.note img
{
  display: inline-block;
  vertical-align: top;
}
.title
{
   display: inline-block;
   font-weight:600;
   font-size:20px;
  vertical-align: top;
  margin-left:15px;
  margin-top:15px;
}
.note
{
  padding:15px;

  display: inline-block;
  vertical-align: top;
  
  background: transparent linear-gradient(90deg, rgba(203, 220, 248, 1) 0%, rgba(221, 213, 247, 1) 100%) 0% 0% no-repeat padding-box;
  border: 1px solid rgba(255, 255, 255, 0);
}
</style>
