<template>
  <div class="related-content">
    <div class="title-related">
      PRODUITS SIMILAIRES
    </div>
    <div  class="related-slider products" >
            <div class="product carousel-cell">
                <img src="../../../public/assets/images/2021/05/lunette.png" class="product-image" />
                <div class="personnalisation-actions">
                    <div class="colors-count">3 <span class="pls">+</span> couleurs</div>
                    <div class="personnalisation-button">Personnaliser</div>
                </div>
                <div class="like">
                    
                    <img src="../../../public/assets/images/heart.svg" width="26" />
                </div>
                <div class="bottom">
                
                    <span class="product-title">SEOUL</span>
                    <span class="product-price">À partir de 80€</span>
                    
                </div>
            </div>
            <div class="product carousel-cell">
                <div class="soon-message">Bientôt disponible</div>
                <div class="flou"></div>
                <img src="../../../public/assets/images/2021/05/lunette.png" class="product-image" />
                <div class="options">
                    <div class="option-head">
                        <span data-toggle="colors" @click="chooseOption('colors')" class="label" v-bind:class="{ active: currentOption=='colors' }">Couleurs</span>
                        <span data-toggle="lenses" @click="chooseOption('lenses')" class="label" v-bind:class="{ active: currentOption=='lenses' }">Verres</span>
                    </div>
                    <div class="option colors" v-if="currentOption=='colors'">
                        <span class="color color1" @click="chooseColor('color1')" v-bind:class="{ active: currentColor=='color1'}"><span class="title">Noir</span><span class="bg"></span><span class="contour"></span></span>
                        <span class="color color2" @click="chooseColor('color2')" v-bind:class="{ active: currentColor=='color2'}"><span class="title">Gris</span><span class="bg" style="background-color:#3A3939;"></span><span class="contour"></span></span>
                        <span class="color color3" @click="chooseColor('color3')" v-bind:class="{ active: currentColor=='color3'}"><span class="title">Vert</span><span class="bg" style="background-color:#2B3718;"></span><span class="contour"></span></span>
                        <span class="color color5" @click="chooseColor('color5')" v-bind:class="{ active: currentColor=='color5'}"><span class="title">Bleu</span><span class="bg" style="background-color:#2D4A95;"></span><span class="contour"></span></span>
                        <span class="color color6" @click="chooseColor('color6')" v-bind:class="{ active: currentColor=='color6'}"><span class="title">Rouge</span><span class="bg" style="background-color:#922B2A;"></span><span class="contour"></span></span>
                        <span class="color color7" @click="chooseColor('color7')" v-bind:class="{ active: currentColor=='color7'}"><span class="title">Rose</span><span class="bg" style="background-color:#D0938B;"></span><span class="contour"></span></span>
                        <span class="color color9" @click="chooseColor('color9')" v-bind:class="{ active: currentColor=='color9'}"><span class="title">Blanc</span><span class="bg" style="background-color:#ffffff;"></span><span class="contour"></span></span>
                    </div>
                    <div class="option lenses" v-if="currentOption=='lenses'">
                        <span class="color color1" @click="chooseColor('color1')" v-bind:class="{ active: currentColor=='color1'}"><span class="title">Noir</span><img src="../../../public/assets/images/2021/05/noir.png" width="40" class="bg bg-lense" /><span class="contour"></span></span>
                        <span class="color color2" @click="chooseColor('color2')" v-bind:class="{ active: currentColor=='color2'}"><span class="title">Transparent</span><img src="../../../public/assets/images/2021/05/transparent.png" width="40" class="bg bg-lense" /><span class="contour"></span></span>
                        <span class="color color3" @click="chooseColor('color3')" v-bind:class="{ active: currentColor=='color3'}"><span class="title">Jaune</span><img src="../../../public/assets/images/2021/05/jaune.png" width="40" class="bg bg-lense" /><span class="contour"></span></span>
                    </div>
                </div>
                
                
            </div>
            <div class="product carousel-cell">
            <div class="soon-message">Bientôt disponible</div>
            <div class="flou"></div>
                <img src="../../../public/assets/images/2021/05/lunette.png" class="product-image" />
                <div class="options">
                    <div class="option-head">
                        <span data-toggle="colors" @click="chooseOption('colors')" class="label" v-bind:class="{ active: currentOption=='colors' }">Couleurs</span>
                        <span data-toggle="lenses" @click="chooseOption('lenses')" class="label" v-bind:class="{ active: currentOption=='lenses' }">Verres</span>
                    </div>
                    <div class="option colors" v-if="currentOption=='colors'">
                        <span class="color color1" @click="chooseColor('color1')" v-bind:class="{ active: currentColor=='color1'}"><span class="title">Noir</span><span class="bg"></span><span class="contour"></span></span>
                        <span class="color color2" @click="chooseColor('color2')" v-bind:class="{ active: currentColor=='color2'}"><span class="title">Gris</span><span class="bg" style="background-color:#3A3939;"></span><span class="contour"></span></span>
                        <span class="color color3" @click="chooseColor('color3')" v-bind:class="{ active: currentColor=='color3'}"><span class="title">Vert</span><span class="bg" style="background-color:#2B3718;"></span><span class="contour"></span></span>
                        <span class="color color5" @click="chooseColor('color5')" v-bind:class="{ active: currentColor=='color5'}"><span class="title">Bleu</span><span class="bg" style="background-color:#2D4A95;"></span><span class="contour"></span></span>
                        <span class="color color6" @click="chooseColor('color6')" v-bind:class="{ active: currentColor=='color6'}"><span class="title">Rouge</span><span class="bg" style="background-color:#922B2A;"></span><span class="contour"></span></span>
                        <span class="color color7" @click="chooseColor('color7')" v-bind:class="{ active: currentColor=='color7'}"><span class="title">Rose</span><span class="bg" style="background-color:#D0938B;"></span><span class="contour"></span></span>
                        <span class="color color9" @click="chooseColor('color9')" v-bind:class="{ active: currentColor=='color9'}"><span class="title">Blanc</span><span class="bg" style="background-color:#ffffff;"></span><span class="contour"></span></span>
                    </div>
                    <div class="option lenses" v-if="currentOption=='lenses'">
                        <span class="color color1" @click="chooseColor('color1')" v-bind:class="{ active: currentColor=='color1'}"><span class="title">Noir</span><img src="../../../public/assets/images/2021/05/noir.png" width="40" class="bg bg-lense" /><span class="contour"></span></span>
                        <span class="color color2" @click="chooseColor('color2')" v-bind:class="{ active: currentColor=='color2'}"><span class="title">Transparent</span><img src="../../../public/assets/images/2021/05/transparent.png" width="40" class="bg bg-lense" /><span class="contour"></span></span>
                        <span class="color color3" @click="chooseColor('color3')" v-bind:class="{ active: currentColor=='color3'}"><span class="title">Jaune</span><img src="../../../public/assets/images/2021/05/jaune.png" width="40" class="bg bg-lense" /><span class="contour"></span></span>
                    </div>
                </div>
                
                
            </div>
            
            <div class="product carousel-cell">
                <div class="soon-message">Bientôt disponible</div>
                <div class="flou"></div>
                <img src="../../../public/assets/images/2021/05/lunette.png" class="product-image" />
                <div class="options">
                    <div class="option-head">
                        <span data-toggle="colors" @click="chooseOption('colors')" class="label" v-bind:class="{ active: currentOption=='colors' }">Couleurs</span>
                        <span data-toggle="lenses" @click="chooseOption('lenses')" class="label" v-bind:class="{ active: currentOption=='lenses' }">Verres</span>
                    </div>
                    <div class="option colors" v-if="currentOption=='colors'">
                        <span class="color color1" @click="chooseColor('color1')" v-bind:class="{ active: currentColor=='color1'}"><span class="title">Noir</span><span class="bg"></span><span class="contour"></span></span>
                        <span class="color color2" @click="chooseColor('color2')" v-bind:class="{ active: currentColor=='color2'}"><span class="title">Gris</span><span class="bg" style="background-color:#3A3939;"></span><span class="contour"></span></span>
                        <span class="color color3" @click="chooseColor('color3')" v-bind:class="{ active: currentColor=='color3'}"><span class="title">Vert</span><span class="bg" style="background-color:#2B3718;"></span><span class="contour"></span></span>
                        <span class="color color5" @click="chooseColor('color5')" v-bind:class="{ active: currentColor=='color5'}"><span class="title">Bleu</span><span class="bg" style="background-color:#2D4A95;"></span><span class="contour"></span></span>
                        <span class="color color6" @click="chooseColor('color6')" v-bind:class="{ active: currentColor=='color6'}"><span class="title">Rouge</span><span class="bg" style="background-color:#922B2A;"></span><span class="contour"></span></span>
                        <span class="color color7" @click="chooseColor('color7')" v-bind:class="{ active: currentColor=='color7'}"><span class="title">Rose</span><span class="bg" style="background-color:#D0938B;"></span><span class="contour"></span></span>
                        <span class="color color9" @click="chooseColor('color9')" v-bind:class="{ active: currentColor=='color9'}"><span class="title">Blanc</span><span class="bg" style="background-color:#ffffff;"></span><span class="contour"></span></span>
                    </div>
                    <div class="option lenses" v-if="currentOption=='lenses'">
                        <span class="color color1" @click="chooseColor('color1')" v-bind:class="{ active: currentColor=='color1'}"><span class="title">Noir</span><img src="../../../public/assets/images/2021/05/noir.png" width="40" class="bg bg-lense" /><span class="contour"></span></span>
                        <span class="color color2" @click="chooseColor('color2')" v-bind:class="{ active: currentColor=='color2'}"><span class="title">Transparent</span><img src="../../../public/assets/images/2021/05/transparent.png" width="40" class="bg bg-lense" /><span class="contour"></span></span>
                        <span class="color color3" @click="chooseColor('color3')" v-bind:class="{ active: currentColor=='color3'}"><span class="title">Jaune</span><img src="../../../public/assets/images/2021/05/jaune.png" width="40" class="bg bg-lense" /><span class="contour"></span></span>
                    </div>
                </div>
                
            </div>
            <div class="product carousel-cell">
                <div class="soon-message">Bientôt disponible</div>
                <div class="flou"></div>
                <img src="../../../public/assets/images/2021/05/lunette.png" class="product-image" />
               <div class="options">
                    <div class="option-head">
                        <span data-toggle="colors" @click="chooseOption('colors')" class="label" v-bind:class="{ active: currentOption=='colors' }">Couleurs</span>
                        <span data-toggle="lenses" @click="chooseOption('lenses')" class="label" v-bind:class="{ active: currentOption=='lenses' }">Verres</span>
                    </div>
                    <div class="option colors" v-if="currentOption=='colors'">
                        <span class="color color1" @click="chooseColor('color1')" v-bind:class="{ active: currentColor=='color1'}"><span class="title">Noir</span><span class="bg"></span><span class="contour"></span></span>
                        <span class="color color2" @click="chooseColor('color2')" v-bind:class="{ active: currentColor=='color2'}"><span class="title">Gris</span><span class="bg" style="background-color:#3A3939;"></span><span class="contour"></span></span>
                        <span class="color color3" @click="chooseColor('color3')" v-bind:class="{ active: currentColor=='color3'}"><span class="title">Vert</span><span class="bg" style="background-color:#2B3718;"></span><span class="contour"></span></span>
                        <span class="color color5" @click="chooseColor('color5')" v-bind:class="{ active: currentColor=='color5'}"><span class="title">Bleu</span><span class="bg" style="background-color:#2D4A95;"></span><span class="contour"></span></span>
                        <span class="color color6" @click="chooseColor('color6')" v-bind:class="{ active: currentColor=='color6'}"><span class="title">Rouge</span><span class="bg" style="background-color:#922B2A;"></span><span class="contour"></span></span>
                        <span class="color color7" @click="chooseColor('color7')" v-bind:class="{ active: currentColor=='color7'}"><span class="title">Rose</span><span class="bg" style="background-color:#D0938B;"></span><span class="contour"></span></span>
                        <span class="color color9" @click="chooseColor('color9')" v-bind:class="{ active: currentColor=='color9'}"><span class="title">Blanc</span><span class="bg" style="background-color:#ffffff;"></span><span class="contour"></span></span>
                    </div>
                    <div class="option lenses" v-if="currentOption=='lenses'">
                        <span class="color color1" @click="chooseColor('color1')" v-bind:class="{ active: currentColor=='color1'}"><span class="title">Noir</span><img src="../../../public/assets/images/2021/05/noir.png" width="40" class="bg bg-lense" /><span class="contour"></span></span>
                        <span class="color color2" @click="chooseColor('color2')" v-bind:class="{ active: currentColor=='color2'}"><span class="title">Transparent</span><img src="../../../public/assets/images/2021/05/transparent.png" width="40" class="bg bg-lense" /><span class="contour"></span></span>
                        <span class="color color3" @click="chooseColor('color3')" v-bind:class="{ active: currentColor=='color3'}"><span class="title">Jaune</span><img src="../../../public/assets/images/2021/05/jaune.png" width="40" class="bg bg-lense" /><span class="contour"></span></span>
                    </div>
                </div>
                
            </div>
            <div class="product carousel-cell">
                <div class="soon-message">Bientôt disponible</div>
                <div class="flou"></div>
                <img src="../../../public/assets/images/2021/05/lunette.png" class="product-image" />
                <div class="options">
                    <div class="option-head">
                        <span data-toggle="colors" @click="chooseOption('colors')" class="label" v-bind:class="{ active: currentOption=='colors' }">Couleurs</span>
                        <span data-toggle="lenses" @click="chooseOption('lenses')" class="label" v-bind:class="{ active: currentOption=='lenses' }">Verres</span>
                    </div>
                    <div class="option colors" v-if="currentOption=='colors'">
                        <span class="color color1" @click="chooseColor('color1')" v-bind:class="{ active: currentColor=='color1'}"><span class="title">Noir</span><span class="bg"></span><span class="contour"></span></span>
                        <span class="color color2" @click="chooseColor('color2')" v-bind:class="{ active: currentColor=='color2'}"><span class="title">Gris</span><span class="bg" style="background-color:#3A3939;"></span><span class="contour"></span></span>
                        <span class="color color3" @click="chooseColor('color3')" v-bind:class="{ active: currentColor=='color3'}"><span class="title">Vert</span><span class="bg" style="background-color:#2B3718;"></span><span class="contour"></span></span>
                        <span class="color color5" @click="chooseColor('color5')" v-bind:class="{ active: currentColor=='color5'}"><span class="title">Bleu</span><span class="bg" style="background-color:#2D4A95;"></span><span class="contour"></span></span>
                        <span class="color color6" @click="chooseColor('color6')" v-bind:class="{ active: currentColor=='color6'}"><span class="title">Rouge</span><span class="bg" style="background-color:#922B2A;"></span><span class="contour"></span></span>
                        <span class="color color7" @click="chooseColor('color7')" v-bind:class="{ active: currentColor=='color7'}"><span class="title">Rose</span><span class="bg" style="background-color:#D0938B;"></span><span class="contour"></span></span>
                        <span class="color color9" @click="chooseColor('color9')" v-bind:class="{ active: currentColor=='color9'}"><span class="title">Blanc</span><span class="bg" style="background-color:#ffffff;"></span><span class="contour"></span></span>
                    </div>
                    <div class="option lenses" v-if="currentOption=='lenses'">
                        <span class="color color1" @click="chooseColor('color1')" v-bind:class="{ active: currentColor=='color1'}"><span class="title">Noir</span><img src="../../../public/assets/images/2021/05/noir.png" width="40" class="bg bg-lense" /><span class="contour"></span></span>
                        <span class="color color2" @click="chooseColor('color2')" v-bind:class="{ active: currentColor=='color2'}"><span class="title">Transparent</span><img src="../../../public/assets/images/2021/05/transparent.png" width="40" class="bg bg-lense" /><span class="contour"></span></span>
                        <span class="color color3" @click="chooseColor('color3')" v-bind:class="{ active: currentColor=='color3'}"><span class="title">Jaune</span><img src="../../../public/assets/images/2021/05/jaune.png" width="40" class="bg bg-lense" /><span class="contour"></span></span>
                    </div>
                </div>
                
            </div>
            
            
        </div>
        <center>
          <div id="prev-related"><img src="../../../public/assets/images/prev.svg" width="51" /></div>
          <div id="next-related"><img src="../../../public/assets/images/nexy.svg" width="51" /></div>
        </center>
  </div>
</template>

<script>
import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider"
import {APIRequest} from "@simb2s/senseye-sdk";

export default {
  name: 'RelatedSlider',
  data() {
    return {
      glasses: null,
      relatedSliderOptions: {
            container: '.related-slider',
            mouseDrag: true,
            navPosition:'bottom',
            loop: false,
            items: 1,
            loop:true,
            center:false, 
            swipeAngle: 0,
            nav:false,
            controls:true,
            prevButton:'#prev-related',  // Node reference
            nextButton: '#next-related',
            startIndex:0,
            responsive:{
                760:{
                    items:3.7
                }
            }
        },
      slider_related:null,
      
    }
  },
  async mounted(){

     this.slider_related = tns(
        this.relatedSliderOptions
     );
      
  },
  methods:{
    async getGlasses(){
      
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width:760px)
{
    body div.products div.product
    
    {
        margin-left:0;
        margin-right:0 !important;
    }
    body .title-related
    {
        font-size:32px;
    }
}
.title-related
{
  font-size:50px;
  text-align: center;
  margin-top:100px;
  margin-bottom:50px;
}
#prev-related,#next-related
{
  display: inline-block;
  margin-left:20px;
  margin-right:20px;
}
.colors-count .pls
{
background: linear-gradient(80deg, #95B4F2 0%, #9F96F0 100%) 0% 0% no-repeat padding-box;
-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.personnalisation-button::before
{
    content:' ';
    position:absolute;
    left:5px;
    top: 7px;
    width:13px;
    height:13px;
    background-size:100% 100%;
    display:block;
    background-image:url(../../../public/assets/images/bullet.svg);
}
.personnalisation-button
{
    position:relative;
    font-size:13px;
    background: transparent linear-gradient(90deg, #CBDCF8 0%, #DDD5F7 100%) 0% 0% no-repeat padding-box;
border: 1px solid #FFFFFF00;
border-radius: 8px;
padding:5px 10px 5px 25px;
}
.like
{
    cursor:pointer;
    position:absolute;
    top:10px;
    z-index:2;
    text-align: right;
    right:15px;
}
.personnalisation-actions
{
    position:absolute;
    top:10px;
    text-align: left;
    left:15px;
}
.product.carousel-cell .flou
{
    height:100%;
}
body div.products div.product
{
  margin-left:0;
  min-height:0;
  margin-right:1%;
}
.colors-count
{
    
    font-size:13px;

}
.related-content
{
  padding-left:5%;
   padding-right:5%;
}
.products .product .bottom .product-title
{
	display: inline-block;
    font-size:18px;
	text-transform: uppercase;
}
</style>
